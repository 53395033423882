<template>
  <div class="fluency-markdown-editor">
    <textarea ref="target"></textarea>
    <p-progress-bar v-if="uploadingFile"
                    class="mt-3"
                    :value="uploadPercentage"/>
    <div v-else class="d-flex justify-content-end">
      <span class="text-muted font-italic">
        Drag and drop images right onto the editor to add them to your post.
      </span>
    </div>
  </div>
</template>
<script>
import EasyMDE from 'easymde'
import 'easymde/dist/easymde.min.css'

export default {
  name: 'MarkdownInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    toolbar: {
      type: Array,
      default: () => ['bold', 'italic', 'heading', '|', 'ordered-list', 'unordered-list', 'code', 'quote', '|', 'link', 'image', 'horizontal-rule', '|', 'preview', 'guide']
    }
  },
  data () {
    return {
      uploadingFile: false,
      uploadPercentage: 0
    }
  },
  methods: {
    init () {
      const mde = new EasyMDE({
        element: this.$refs.target,
        initialValue: this.value,
        toolbar: this.toolbar,
        status: false,
        uploadImage: true,
        imagePathAbsolute: true,
        imageUploadFunction: this.handleImageUpload
      })
      mde.codemirror.on('change', () => {
        this.$emit('input', mde.value())
      })
      mde.codemirror.on('blur', () => {
        this.$emit('blur')
      })
    },
    async handleImageUpload (file, onSuccess, onError) {
      this.uploadingFile = true
      const formData = new FormData()
      formData.append('file', file, file.name)
      const response = await this.$res.set.imageUpload(formData, progressEvent => {
        this.uploadPercentage = (Math.round((progressEvent.loaded / progressEvent.total) * 100))
      })
      if (response) {
        onSuccess(response.location)
      }
      this.uploadingFile = false
      this.uploadPercentage = 0
    }
  },
  mounted () {
    this.init()
  }
}
</script>
